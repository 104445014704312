import produce from 'immer';
import { ActionTypes, API_STORE_NAME } from 'context/actions/ApiDataActions';
import { AppAction } from 'context/AppState';
import { ApiDataState } from './ApiDataTypes';
import { checkIfNoSeedsVarietiesAvailable } from 'utils/getRecommendedSeeds';

export const ApiDataInitialState: ApiDataState = {
  countryList: [],
  countryListLoading: false,
  countryListError: undefined,

  attributes: [],
  attributesError: undefined,
  attributesLoading: false,

  productMaturityRange: { minFAO: 0, maxFAO: 0 },
  productMaturityRangeError: undefined,
  productMaturityRangeLoading: false,

  fieldSoils: {
    fieldsResolution: 0.0,
    soilGrid: {},
  },
  fieldSoilsError: undefined,
  fieldSoilsLoading: false,

  recommendation: null,
  recommendationError: undefined,
  recommendationLoading: false,

  recommendationCropwise: null,
  recommendationCropwiseError: undefined,
  recommendationCropwiseLoading: false,

  breakoutsGlobal: {},
  breakoutsGlobalError: undefined,
  breakoutsGlobalLoading: false,

  breakoutsGlobalWithFilters: {},
  breakoutsGlobalErrorWithFilters: undefined,
  breakoutsGlobalLoadingWithFilters: false,

  breakoutsProductsSummaryWithFilters: [],
  breakoutsProductsSummary: [],
  breakoutsProductsSummaryError: undefined,
  breakoutsProductsSummaryLoading: false,

  productCatalog: [],
  productCatalogError: undefined,
  productCatalogLoading: false,

  weather: [],
  weatherError: undefined,
  weatherLoading: false,

  tppData: {
    ID_0: [],
    ISO: [],
    NAME_0: [],
    TPP_group: [],
    products: [],
    spatialClass: [],
  },
  tppDataError: undefined,
  tppDataLoading: false,

  downyMildew: {
    value: [],
    dm_risk: undefined,
  },
  downyMildewError: undefined,
  downyMildewLoading: false,

  recommenationValidateLoading: false,
  recommenationValidateError: undefined,
  noSeedsAvailable: false,

  fieldsAttributes: {
    TPP: '',
    codigo_ibg: '',
    region_id: '',
    TPP_group: '',
    Country: '',
    MESO: '',
    MESO_FULL: '',
    MESO_IBGE: '',
    State: '',
  },
  fieldsAttributesLoading: false,
  fieldsAttributesError: undefined,

  arableLand: {},
  arableLandLoading: false,

  formattedDetectFieldsData: {
    type: 'FeatureCollection',
    features: [],
  },
  detectFieldsLoading: false,
  detectFieldsError: undefined,

  nonDDGUserDetails: {},
  nonDDGUserDetailsLoading: true,
  nonDDGUserDetailsError: undefined,

  growthStage: [],
  growthStageLoading: false,
  growthStageError: undefined,
};

export const ApiDataReducer = produce((draft: ApiDataState, action: AppAction) => {
  const { payload = {} } = action;

  switch (action.type) {
    case ActionTypes.setCountries:
      draft.countryList = payload.data;
      draft.countryListLoading = false;
      draft.countryListError = undefined;
      break;
    case ActionTypes.setCountriesLoading:
      draft.countryListLoading = payload.loading;
      draft.countryListError = undefined;
      break;
    case ActionTypes.setCountryListError:
      draft.countryListError = payload.error;
      draft.countryListLoading =
        payload.error.code !== 'ERR_CANCELED' ? false : draft.productCatalogLoading;
      break;

    case ActionTypes.setAttributes:
      draft.attributes = payload.data;
      draft.attributesLoading = false;
      draft.attributesError = undefined;
      break;
    case ActionTypes.setAttributesLoading:
      draft.attributesLoading = payload.loading;
      draft.attributesError = undefined;
      break;
    case ActionTypes.setAttributesError:
      draft.attributesError = payload.error;
      draft.attributesLoading = false;
      break;

    case ActionTypes.setProductMaturityRangeFAO:
      draft.productMaturityRange = payload.data;
      draft.productMaturityRangeLoading = false;
      draft.productMaturityRangeError = undefined;
      break;
    case ActionTypes.setProductMaturityRangeFAOLoading:
      draft.productMaturityRangeLoading = payload.loading;
      draft.productMaturityRangeError = undefined;
      break;
    case ActionTypes.setProductMaturityRangeFAOError:
      draft.productMaturityRangeError = payload.error;
      draft.productMaturityRangeLoading = false;
      break;

    case ActionTypes.setFieldSoils:
      draft.fieldSoils = payload.data;
      draft.fieldSoilsLoading = false;
      draft.fieldSoilsError = undefined;
      break;
    case ActionTypes.setFieldSoilsLoading:
      draft.fieldSoilsLoading = payload.loading;
      draft.fieldSoilsError = undefined;
      break;
    case ActionTypes.setFieldSoilsError:
      draft.fieldSoilsError = payload.error;
      draft.fieldSoilsLoading = false;
      break;

    case ActionTypes.setRecommendation:
      draft.recommendation = payload.data;
      draft.recommendationLoading = false;
      draft.recommendationError = undefined;
      break;
    case ActionTypes.setRecommendationLoading:
      draft.recommendationLoading = payload.loading;
      draft.recommendationError = undefined;
      break;
    case ActionTypes.setRecommendationError:
      draft.recommendationError = payload.error;
      draft.recommendationLoading = false;
      break;

    case ActionTypes.setRecommendationCropwise:
      draft.recommendationCropwise = payload.data;
      draft.recommendationCropwiseLoading = false;
      draft.recommendationCropwiseError = undefined;
      break;
    case ActionTypes.setRecommendationCropwiseLoading:
      draft.recommendationCropwiseLoading = payload.loading;
      draft.recommendationCropwiseError = undefined;
      break;
    case ActionTypes.setRecommendationCropwiseError:
      draft.recommendationCropwiseError = payload.error;
      draft.recommendationCropwiseLoading = false;
      break;

    case ActionTypes.setBreakoutsGlobal:
      draft.breakoutsGlobal = {
        ...draft.breakoutsGlobal,
        [payload.productName]: payload.data,
      };
      draft.breakoutsGlobalLoading = false;
      draft.breakoutsGlobalError = undefined;
      break;
    case ActionTypes.setBreakoutsGlobalLoading:
      draft.breakoutsGlobalLoading = payload.loading;
      draft.breakoutsGlobalError = undefined;
      break;
    case ActionTypes.setBreakoutsGlobalError:
      draft.breakoutsGlobalError = payload.error;
      draft.breakoutsGlobalLoading = false;
      break;

    case ActionTypes.setBreakoutsProductsSummary:
      draft.breakoutsProductsSummary = payload.data;
      draft.breakoutsProductsSummaryLoading = false;
      draft.breakoutsGlobalError = undefined;
      break;
    case ActionTypes.setBreakoutsProductsSummaryLoading:
      draft.breakoutsProductsSummaryLoading = payload.loading;
      draft.breakoutsProductsSummaryError = undefined;
      break;
    case ActionTypes.setBreakoutsProductsSummaryError:
      draft.breakoutsProductsSummaryError = payload.error;
      draft.breakoutsProductsSummaryLoading = false;
      break;
    case ActionTypes.setBreakoutsProductsSummaryWithFilters:
      draft.breakoutsProductsSummaryWithFilters = payload.data;
      draft.breakoutsGlobalLoadingWithFilters = false;
      draft.breakoutsGlobalErrorWithFilters = undefined;
      break;

    case ActionTypes.setBreakoutsGlobalWithFilters:
      draft.breakoutsGlobalWithFilters = {
        ...draft.breakoutsGlobalWithFilters,
        [payload.productName]: payload.data,
      };
      draft.breakoutsGlobalLoadingWithFilters = false;
      draft.breakoutsGlobalErrorWithFilters = undefined;
      break;
    case ActionTypes.setBreakoutsGlobalWithFiltersLoading:
      draft.breakoutsGlobalLoadingWithFilters = payload.loading;
      draft.breakoutsGlobalErrorWithFilters = undefined;
      break;
    case ActionTypes.setBreakoutsGlobalWithFiltersError:
      draft.breakoutsGlobalErrorWithFilters = payload.error;
      draft.breakoutsGlobalLoadingWithFilters = false;
      break;

    case ActionTypes.setProductCatalog:
      draft.productCatalog = [...payload.data];
      draft.productCatalogLoading = false;
      draft.productCatalogError = undefined;
      break;
    case ActionTypes.setProductCatalogLoading:
      draft.productCatalogLoading = payload.loading;
      draft.productCatalogError = undefined;
      break;
    case ActionTypes.setProductCatalogError:
      draft.productCatalogError = payload.error;
      draft.productCatalogLoading =
        payload.error.code !== 'ERR_CANCELED' ? false : draft.productCatalogLoading;
      break;

    case ActionTypes.setWeather:
      draft.weather = [...payload.data];
      draft.weatherLoading = false;
      draft.weatherError = undefined;
      break;
    case ActionTypes.setWeatherError:
      draft.weatherLoading = false;
      draft.weatherError = payload.error;
      break;
    case ActionTypes.setWeatherLoading:
      draft.weatherLoading = payload.loading;
      draft.weatherError = undefined;
      break;

    case ActionTypes.setTPPForFields:
      draft.tppData = payload.data;
      draft.tppDataLoading = false;
      draft.tppDataError = undefined;
      break;
    case ActionTypes.setTPPForFieldsError:
      draft.tppDataLoading = false;
      draft.tppDataError = payload.error;
      break;
    case ActionTypes.setTPPForFieldsLoading:
      draft.tppDataLoading = payload.loading;
      draft.tppDataError = undefined;
      break;
    case ActionTypes.setDownyMildew:
      draft.downyMildew = payload.data;
      draft.downyMildewLoading = false;
      draft.downyMildewError = undefined;
      break;
    case ActionTypes.setDownyMildewError:
      draft.downyMildewLoading = false;
      draft.downyMildewError = payload.error;
      break;
    case ActionTypes.setDownyMildewLoading:
      draft.downyMildewLoading = payload.loading;
      draft.downyMildewError = undefined;
      break;
    case ActionTypes.setRecommendationValidation:
      draft.noSeedsAvailable = checkIfNoSeedsVarietiesAvailable(payload.data);
      draft.recommenationValidateLoading = false;
      draft.recommenationValidateError = undefined;
      break;
    case ActionTypes.setRecommendationValidationError:
      draft.recommenationValidateLoading = false;
      draft.recommenationValidateError = payload.error;
      break;
    case ActionTypes.setRecommendationValidationLoading:
      draft.recommenationValidateLoading = payload.loading;
      draft.recommenationValidateError = undefined;
      break;
    case ActionTypes.setFieldsAttribute:
      draft.fieldsAttributes = payload.data;
      draft.fieldsAttributesLoading = false;
      draft.fieldsAttributesError = undefined;
      break;
    case ActionTypes.setFieldsAttributeError:
      draft.fieldsAttributesLoading = false;
      draft.fieldsAttributesError = payload.error;
      break;
    case ActionTypes.setFieldsAttributeLoading:
      draft.fieldsAttributesLoading = payload.loading;
      draft.fieldsAttributesError = undefined;
      break;
    case ActionTypes.setDetectField:
      draft.formattedDetectFieldsData = { ...payload.formattedDetectField };
      draft.detectFieldsLoading = false;
      draft.detectFieldsError = undefined;
      break;
    case ActionTypes.setDetectFieldError:
      draft.detectFieldsLoading = false;
      draft.detectFieldsError = payload.error;
      break;
    case ActionTypes.setDetectFieldLoading:
      draft.detectFieldsLoading = payload.loading;
      draft.detectFieldsError = undefined;
      break;
    case ActionTypes.setArableLand:
      draft.arableLand = payload.data;
      draft.arableLandLoading = false;
      break;
    case ActionTypes.setArableLandLoading:
      draft.arableLandLoading = payload.loading;
      break;
    case ActionTypes.setNonDDGUserDetails:
      draft.nonDDGUserDetails = payload.data;
      draft.nonDDGUserDetailsLoading = false;
      draft.nonDDGUserDetailsError = undefined;
      break;
    case ActionTypes.setNonDDGUserDetailsError:
      draft.nonDDGUserDetailsLoading = false;
      draft.nonDDGUserDetailsError = payload.error;
      break;
    case ActionTypes.setNonDDGUserDetailsLoading:
      draft.nonDDGUserDetailsLoading = payload.loading;
      draft.nonDDGUserDetailsError = undefined;
      break;
    case ActionTypes.setGrowthStage:
      draft.growthStage = payload.data;
      draft.growthStageLoading = false;
      draft.growthStageError = undefined;
      break;
    case ActionTypes.setGrowthStageError:
      draft.growthStageLoading = false;
      draft.growthStageError = payload.error;
      break;
    case ActionTypes.setGrowthStageLoading:
      draft.growthStageLoading = payload.loading;
      draft.growthStageError = undefined;
      break;
    case 'reset-app':
      // Reset Errors when user goes to Home
      draft.countryListError = undefined;
      draft.attributesError = undefined;
      draft.productMaturityRangeError = undefined;
      draft.fieldSoilsError = undefined;
      draft.tppDataError = undefined;
      draft.downyMildewError = undefined;
      draft.recommenationValidateError = undefined;
      break;
    default:
      break;
  }

  // Save API Cache
  window.sessionStorage.setItem(API_STORE_NAME, JSON.stringify(draft));
});
