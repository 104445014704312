import { ActionTypes } from 'context/actions/flowActions';
import { AppAction } from 'context/AppState';
import produce from 'immer';
import { RequestError } from '../../base/types/Error';
import { Country } from 'base/types/Countries';
import { BarChartLabelProps } from 'components/BarChart/BarChartLabel';
import { DetectFieldResponse } from 'base/types/detectField';
import { detectFieldFeature } from './recommendationFormReducer';

import { SeedDensityField } from 'pages/RecommendationV2/Sections/SeedDensityAdvice';
import { Field, recommendationResults } from 'base/types/RecommendationCropwise';
import { PinInfoType } from 'pages/RecommendationV2/RecommendationV2';

export enum OptionType {
  empty = '',
  Draw = 'DRAW',
  Drop = 'DROP',
  Detect = 'DETECT',
  Spot_Check = 'spot_check',
}

export enum FlowSteps {
  empty = '',
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
  STEP4 = 'STEP4',
}

export enum ModeTypes {
  CREATING = 'CREATING',
  EDITING = 'EDITING',
  CANCELLING = 'CANCELLING',
  SAVING = 'SAVING',
  NO_ACTION = 'NO_ACTION',
  CONFIRMING_AFTER_EDIT = 'CONFIRMING_AFTER_EDIT',
  DELETING = 'DELETING',
}

export enum OverlappingGeometryTypes {
  NONE = 'NONE',
  POINT = 'POINT',
  LINE = 'LINE',
  POLYGON = 'POLYGON',
}

export enum StressType {
  Frequency = 'Stress Frequency',
  Intensity = 'Stress Intensity',
}

export interface TableViewProps {
  id?: string;
  tabInfo: recommendationResults[] | null;
  propertiesFieldsInfo: Field[] | null;
  isDropAPin: boolean;
  pinInfo: PinInfoType | null;
}

export interface SelectedProductFieldInfo {
  fieldName: string;
  originalFieldName: string;
  coords: [number, number][];
  size: number | string;
  color?: string;
}

export const TOTAL_STEPS = 3;
export interface FlowState {
  [key: string]: any;
  currentStep: FlowSteps;
  optionType: OptionType;
  showPin?: boolean;
  showPinInformationModal?: boolean;
  showButtonHub?: boolean;
  disabledButtonHub?: boolean;
  showMapTools?: boolean;
  disabledMapTools?: boolean;
  isLocationEnabled?: boolean;
  showCookiesMsg?: boolean;
  disabledButtonNext?: boolean;
  isDrawingBoundaries: boolean;
  tempDetectFieldSelected: string[];
  showDrawingWarning: boolean;
  showPinWarning: boolean;
  areBoundariesConfirmed?: boolean;
  arePinConfirmed: boolean;
  showCropTypeDrawer: boolean;
  showDeleteBoundaryModal?: boolean;
  deleteBoundaryAction?: boolean;
  editBoundaryAction?: boolean;
  undoDraw?: boolean;
  showSpinner?: boolean;
  showAgronomicInputs?: boolean;
  showGoBackHomeModal?: boolean;
  showAgronomicWeightings?: boolean;
  showEnvironmentDrawer: boolean;
  showGetRecommendationmodal?: boolean;
  showUpdateInfoMessage?: boolean;
  resetBoundaryValues?: boolean;
  fieldToBeSaved?: boolean;
  requestError?: RequestError;
  showCancelBoundaryModal?: boolean;
  showEditBoundaryModal?: boolean;
  showEnvironmentInformationModal?: boolean;
  showFieldForm?: boolean;
  emailRecommendationSent?: boolean;
  localCountry: string;
  countries?: Country[];
  currentModeType: ModeTypes;
  previousModeType: ModeTypes;
  isMapBounded: boolean;
  mobileActiveTab: 'Map' | 'List';
  isFetching: boolean;
  appLanguage: string;
  isListTabDisabled: boolean;
  showInvalidBoundaryModal: boolean;
  emailSentCount: number;
  emailUpdatedCount: number;
  phoneNumberUpdatedCount: number;
  emailUpdatedList: string[];
  environmentalStressBarChartData: BarChartLabelProps[];
  environmentalStressTableChartProps: TableViewProps;
  seedDensityAdviceData?: SeedDensityField[];
  journeyCompleted?: boolean;
  showEnvironmentPopup?: boolean;
  isContinueButtonActive?: boolean;
  isAnotherRecommendationCreated: boolean;
  showLandIsNotArableModal?: boolean;
  saveWithoutClosingBoundary?: boolean;
  overlappingGeometryType: OverlappingGeometryTypes;
  isDropAPinFlow?: boolean;
  isModalToBeClosed: boolean;
  isLongTermMobileDesign?: boolean;
  hasDetectFieldFlowStarted?: boolean;
  detectFieldSelectedIds: (string | number)[];
  fieldsMapData: DetectFieldResponse;
  selectedFieldMapData: DetectFieldResponse;
  tempFieldMapData?: detectFieldFeature;
  selectedProductFieldInfo: SelectedProductFieldInfo[];
  isFieldsView: boolean;
  showLayerSelectionPopUp: boolean;
  userCountryCode?: string;
  showTooltips: boolean;
  abioticStageStressType?: StressType;
  selectedAbioticFrequencyValue?: string;
  selectedAbioticIntensityValue?: string;
}

export const flowInitialState: FlowState = {
  optionType: OptionType.empty,
  currentStep: FlowSteps.STEP1,
  showCookiesMsg: true,
  showPinInformationModal: false,
  showButtonHub: false,
  showTooltips: true,
  disabledButtonHub: false,
  showMapTools: true,
  disabledMapTools: false,
  isLocationEnabled: false,
  disabledButtonNext: true,
  isDrawingBoundaries: false,
  showDrawingWarning: false,
  tempDetectFieldSelected: [],
  showPinWarning: false,
  areBoundariesConfirmed: false,
  arePinConfirmed: false,
  showCropTypeDrawer: false,
  showDeleteBoundaryModal: false,
  deleteBoundaryAction: false,
  editBoundaryAction: false,
  undoDraw: false,
  showSpinner: true,
  showAgronomicInputs: false,
  showGoBackHomeModal: false,
  showAgronomicWeightings: false,
  showEnvironmentDrawer: false,
  showGetRecommendationmodal: false,
  showUpdateInfoMessage: false,
  resetBoundaryValues: false,
  fieldToBeSaved: false,
  showCancelBoundaryModal: false,
  showEditBoundaryModal: false,
  showEnvironmentInformationModal: false,
  showFieldForm: true,
  emailRecommendationSent: false,
  localCountry: 'de',
  isFetching: false,
  currentModeType: ModeTypes.NO_ACTION,
  previousModeType: ModeTypes.NO_ACTION,
  isMapBounded: false,
  mobileActiveTab: 'Map',
  appLanguage: '',
  isListTabDisabled: false,
  showInvalidBoundaryModal: false,
  emailSentCount: 0,
  emailUpdatedCount: 0,
  emailUpdatedList: [],
  phoneNumberUpdatedCount: 0,
  selectedProductFieldInfo: [],
  isFieldsView: true,
  showLayerSelectionPopUp: false,
  environmentalStressBarChartData: [],
  environmentalStressTableChartProps: {
    tabInfo: null,
    propertiesFieldsInfo: null,
    isDropAPin: false,
    pinInfo: null,
  },
  seedDensityAdviceData: [],
  detectFieldSelectedIds: [],
  fieldsMapData: {
    type: '',
    features: [],
  },
  selectedFieldMapData: {
    type: '',
    features: [],
  },
  journeyCompleted: false,
  showPrompt: true,
  addAnother: false,
  showEnvironmentPopup: false,
  isContinueButtonActive: false,
  isAnotherRecommendationCreated: false,
  showLandIsNotArableModal: false,
  saveWithoutClosingBoundary: false,
  overlappingGeometryType: OverlappingGeometryTypes.NONE,
  isDropAPinFlow: false,
  isModalToBeClosed: false,
  isLongTermMobileDesign: true,
  hasDetectFieldFlowStarted: false,
  tempFieldMapData: {
    geometry: {
      coordinates: [],
      type: '',
    },
    id: '',
    properties: {
      fieldUniqueId: '',
      hectares: '',
      id: '',
    },
    type: '',
  },
  userCountryCode: '',
  isSmsEnabledCountryFlag: false,
  isSunflowerEnabledFlag: false,
  abioticStageStressType: StressType.Frequency,
  selectedAbioticFrequencyValue: 'last20',
  selectedAbioticIntensityValue: '',
};

export const flowReducer = produce((draft: FlowState, action: AppAction) => {
  const { payload = {} } = action;
  switch (action.type) {
    case ActionTypes.setCurrentStep:
      draft.currentStep = payload.step;
      break;
    case ActionTypes.setFlowStep:
      draft.currentStep = payload.step;
      if (payload.step === FlowSteps.STEP1 && payload.type) {
        draft.optionType = payload.type;
      }
      break;
    case ActionTypes.setOptionType:
      draft.optionType = payload.type;
      break;
    case ActionTypes.setShowPin:
      draft.showPin = payload.show;
      break;
    case ActionTypes.setShowCookiesMsg:
      draft.showCookiesMsg = payload.show;
      break;
    case ActionTypes.setShowPinInformationModal:
      draft.showPinInformationModal = payload.show;
      break;
    case ActionTypes.setShowButtonHub:
      draft.showButtonHub = payload.show;
      break;
    case ActionTypes.setDisabledButtonHub:
      draft.disabledButtonHub = payload.disabled;
      break;
    case ActionTypes.setShowMapTools:
      draft.showMapTools = payload.show;
      break;
    case ActionTypes.setDisabledMapTools:
      draft.disabledMapTools = payload.disabled;
      break;
    case ActionTypes.setShowTooltips:
      draft.showTooltips = payload.show;
      break;
    case ActionTypes.setIsLocationEnabled:
      draft.isLocationEnabled = action.payload?.isEnabled;
      break;
    case ActionTypes.setDisabledButtonNext:
      draft.disabledButtonNext = payload.disabled;
      break;
    case ActionTypes.setIsDrawingBoundaries:
      draft.isDrawingBoundaries = payload.isDrawing;
      break;
    case ActionTypes.setTempDetectFieldSelected:
      draft.tempDetectFieldSelected = payload.selected;
      break;
    case ActionTypes.setIsDropAPinFlow:
      draft.isDropAPinFlow = payload.isDropAPin;
      break;
    case ActionTypes.setIsModalToBeClosed:
      draft.isModalToBeClosed = payload.isModalToBeClosed;
      break;
    case ActionTypes.setShowDrawingWarning:
      draft.showDrawingWarning = payload.show;
      break;
    case ActionTypes.setShowPinWarning:
      draft.showPinWarning = payload.show;
      break;
    case ActionTypes.setAreBoundariesConfirmed:
      draft.areBoundariesConfirmed = payload.areBoundariesConfirmed;
      break;
    case ActionTypes.setArePinConfirmed:
      draft.arePinConfirmed = payload.arePinConfirmed;
      break;
    case ActionTypes.setShowCropTypeDrawer:
      draft.showCropTypeDrawer = payload.show;
      break;
    case ActionTypes.setShowDeleteBoundaryModal:
      draft.showDeleteBoundaryModal = payload.show;
      draft.showButtonHub = !payload.show;
      break;
    case ActionTypes.setSelectedProductFieldInfo:
      draft.selectedProductFieldInfo = [...payload.data];
      break;
    case ActionTypes.setIsFieldsView:
      draft.isFieldsView = payload.fieldView;
      break;
    case ActionTypes.setShowLayerSelectionPopUp:
      draft.showLayerSelectionPopUp = payload.show;
      break;
    case ActionTypes.setDeleteBoundaryAction:
      draft.deleteBoundaryAction = payload.deleteBoundaryAction;
      break;
    case ActionTypes.setEditBoundaryAction:
      draft.editBoundaryAction = payload.editBoundaryAction;
      break;
    case ActionTypes.setUndoDraw:
      draft.undoDraw = payload.undoDraw;
      break;
    case ActionTypes.setShowSpinner:
      draft.showSpinner = payload.show;
      break;
    case ActionTypes.setShowAgronomicInputs:
      draft.showAgronomicInputs = payload.show;
      break;
    case ActionTypes.setShowGoBackHomeModal:
      draft.showGoBackHomeModal = payload.show;
      break;
    case ActionTypes.setShowAgronomicWeightings:
      draft.showAgronomicWeightings = payload.show;
      break;
    case ActionTypes.setShowEnvironmentDrawer:
      draft.showEnvironmentDrawer = payload.show;
      break;
    case ActionTypes.setShowGetRecommendationModal:
      draft.showGetRecommendationModal = payload.show;
      break;
    case ActionTypes.setShowUpdateInfoMessage:
      draft.showUpdateInfoMessage = payload.show;
      break;
    case ActionTypes.setResetBoundaryValues:
      draft.resetBoundaryValues = payload.resetBoundaryValues;
      break;
    case ActionTypes.setFieldToBeSaved:
      draft.fieldToBeSaved = payload.fieldToBeSaved;
      break;
    case ActionTypes.setRequestError:
      draft.requestError = payload.error;
      break;
    case ActionTypes.setShowCancelBoundaryModal:
      draft.showCancelBoundaryModal = payload.show;
      break;
    case ActionTypes.setShowEditBoundaryModal:
      draft.showEditBoundaryModal = payload.show;
      break;
    case ActionTypes.setShowEnvironmentInformationModal:
      draft.showEnvironmentInformationModal = payload.show;
      break;
    case ActionTypes.setEmailRecommendationSent:
      draft.emailRecommendationSent = payload.sent;
      break;
    case ActionTypes.setShowFieldForm:
      draft.showFieldForm = payload.show;
      break;
    case ActionTypes.setLocalCountry:
      draft.localCountry = payload.country;
      break;
    case ActionTypes.setIsFetching:
      draft.isFetching = payload.isFetching;
      break;
    case ActionTypes.setCurrentModeType:
      draft.currentModeType = payload.modeType;
      break;
    case ActionTypes.setPreviousModeType:
      draft.previousModeType = payload.modeType;
      break;
    case ActionTypes.setIsMapBounded:
      draft.isMapBounded = payload.bounded;
      break;
    case ActionTypes.setMobileActiveTab:
      draft.mobileActiveTab = payload.activeTab;
      break;
    case ActionTypes.setListTabIsDisabled:
      draft.isListTabDisabled = payload.isDisabled;
      break;
    case ActionTypes.setAppLanguage:
      draft.appLanguage = payload.language;
      break;
    case ActionTypes.setShowInvalidBoundaryModal:
      draft.showInvalidBoundaryModal = payload.show;
      break;
    case ActionTypes.setEmailSentCount:
      draft.emailSentCount = payload.count;
      break;
    case ActionTypes.setEmailUpdatedCount:
      draft.emailUpdatedCount = payload.count;
      break;
    case ActionTypes.setPhoneNumberUpdatedCount:
      draft.phoneNumberUpdatedCount = payload.count;
      break;
    case ActionTypes.setEmailUpdatedList:
      draft.emailUpdatedList = payload.list;
      break;
    case ActionTypes.setEnvironmentalStressBarChartData:
      draft.environmentalStressBarChartData = payload.data;
      break;
    case ActionTypes.setEnvironmentalStressTableChartProps:
      draft.environmentalStressTableChartProps = payload.props;
      break;
    case ActionTypes.setSeedDensityAdviceData:
      draft.seedDensityAdviceData = payload.data;
      break;
    case ActionTypes.setDetectFieldSelectedIds:
      draft.detectFieldSelectedIds = payload.data;
      break;
    case ActionTypes.setFieldsMapData:
      draft.fieldsMapData = payload.data;
      break;
    case ActionTypes.setTempFieldMapData:
      draft.tempFieldMapData = payload.data;
      break;
    case ActionTypes.setSelectedFieldMapData:
      draft.selectedFieldMapData = payload.data;
      break;
    case ActionTypes.setJourneyCompleted:
      draft.journeyCompleted = payload.completed;
      break;
    case ActionTypes.setShowPrompt:
      draft.showPrompt = payload.show;
      break;
    case ActionTypes.setSaveWithoutClosingBoundary:
      draft.saveWithoutClosingBoundary = payload.saveWithoutClosingBoundary;
      break;
    case ActionTypes.setAddAnother:
      draft.addAnother = payload.addAnother;
      break;
    case ActionTypes.setShowEnvironmentPopup:
      draft.showEnvironmentPopup = payload.show;
      break;
    case ActionTypes.setContinueButtonActive:
      draft.isContinueButtonActive = payload.active;
      break;
    case ActionTypes.setIsAnotherRecommendationCreated:
      draft.isAnotherRecommendationCreated = payload.isAnotherRecommendationCreated;
      break;
    case ActionTypes.setShowLandIsNotArableModal:
      draft.showLandIsNotArableModal = payload.show;
      break;
    case ActionTypes.setOverlappingGeometryType:
      draft.overlappingGeometryType = payload.type;
      break;
    case ActionTypes.setIsLongTermMobileDesign:
      draft.isLongTermMobileDesign = payload.isLongTermMobileDesign;
      break;
    case ActionTypes.setShowFieldInformationSheet:
      draft.showFieldInformationSheet = payload.showFieldInformationSheet;
      break;
    case ActionTypes.setHasDetectFieldFlowStarted:
      draft.hasDetectFieldFlowStarted = payload.hasDetectFieldFlowStarted;
      break;
    case ActionTypes.setUserCountryCode:
      draft.userCountryCode = payload.country;
      break;
    case ActionTypes.setAbioticStageStressType:
      draft.abioticStageStressType = payload.stressType;
      break;
    case ActionTypes.setAbioticFrequencyValue:
      draft.selectedAbioticFrequencyValue = payload.year;
      break;
    case ActionTypes.setAbioticIntensityValue:
      draft.selectedAbioticIntensityValue = payload.year;
      break;
    default:
      return draft;
  }
});
